body {
    margin: 0;
    padding: 0px;
    font-family: 'Lato';
    background-color: $theme-background-color !important;
    color: black;
    display: flex;
    flex-direction: column;
    font-family: "Lato";
    min-height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif !important;
}

// custom scrollbar

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: $theme-scroll-color ;
}

::-webkit-scrollbar-thumb {
    background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    text-decoration: none;
    color: unset;
}

ul {
    padding: 0;

    li {
        list-style: none;
    }
}

.form-control:focus {}

.container,
.container-fluid {}

// remove blue backgroup chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $theme-color-input inset !important;
    -webkit-text-fill-color: $color-white;

    // color: aqua !important;
}

// .form-control:-webkit-autofill,
// .form-control:-webkit-autofill:hover,
// .form-control:-webkit-autofill:focus,
// .form-control:-webkit-autofill:active {
//     background-color: $theme-color-input;
//     /* Change background color */
//     box-shadow: 0 0 0 30px $theme-color-input inset !important;
//     /* Override autofill box shadow */
//     -webkit-text-fill-color: $color-white;
//     /* Override text color */
// }

// remove icon from number input
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

// remove icon from number input
// input[type=number] {
//     -moz-appearance: textfield;
// }

// cursor
.cursor {
    cursor: pointer;
}

.mt-25 {
    margin-top: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.lh-0-ls-5 {
    line-height: unset !important;
    letter-spacing: 5px;
}

.color-dark {
    color: $color-dark;
}

.color-black {
    color: $color-black;
}

.color-blue {
    color: $color-dark-blue;
}

.color-grey {
    color: $color-grey;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.go-back {
    width: 100%;
    text-align: start;
}

// If using react-select, react-date-picker, floating label than give styling here

.line-podcast {
    /* Vector 199 */
    opacity: 0.1;
    border: 1px solid #FFFFFF;
}

.vertical-line {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    height: 122px;
    box-sizing: border-box;

    @media screen and (max-width: 820px) {
        display: none;
    }
}

.custom-back {
    background-color: $side-nav-color;
    min-height: 963px;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
    border: none !important;
    background-color: transparent !important;
}

.form-floating>.form-control:focus~label::after {
    border: none;
    background-color: transparent !important;
}

.form-floating>.form-control:focus~label {
    border: none !important;
    background-color: transparent !important;
}

.form-floating>.form-control:focus {
    border: none !important;
    background-color: $theme-color-input;
}

.vertical-line-video {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    height: 19px;
    box-sizing: border-box;

    @media screen and (max-width: 820px) {
        display: none;
    }
}


.default-align {
    align-items: start !important;
}

.heading {
    h1 {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        color: #000000;
        width: 60%;

        @media screen and (max-width:991px) {
            font-size: 30px;
            line-height: 40px;
            width: 100%;
        }
    }

    .sub-content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #818181;
    }

    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #000000;

    @media screen and (max-width: 760px) {
        font-size: 34px;
        line-height: 40px;
    }

    @media screen and (max-width: 400px) {
        font-size: 24px;
        line-height: 30px;
    }
}

.btn-leader-board {
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 24px;
        gap: 8px;
        width: 210px;
        height: 48px;
        background: #FFD242;
        border-radius: 12px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.012em;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
}

.thank-you {
    height: 100vh;

    p {
        font-size: 12px;
        font-weight: 600;
    }
}

.thank-you {
    height: 100vh;

    p {
        font-size: 16px;
        font-weight: 600;
    }

    .next-round {
        font-size: 16px;
        font-weight: bolder;
        color: green;

        span {
            font-size: 16px;
            font-weight: 900;
            color: black;
        }
    }
}



.door-page {
    .group {
        gap: 60px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 100%;
        }
    }

    .final-round {
        padding: 10px 0px;
        background: linear-gradient(36.21deg, #FFBB57 33.3%, #FFE0AB 56.23%, #FFA347 82.27%);
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 52px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
    }

    .container {
        perspective: 5000px;
        margin: 100px;
    }

    .doorFrame {
        width: 80px;
        height: 130px;
        // margin: 10px;
        float: right;
        background-color: transparent;
        color: #fff;
        padding: 5px 5px 2px 5px;

        // margin-left: 74px;
        @media screen and (max-width: 768px) {
            padding: 0px;
            height: 100%;
        }

        .promo-code {
            position: absolute;
            z-index: 0;
            width: 100px;
            margin-top: 53px;

            p {
                font-size: 18px;
                font-weight: 900;
                color: black;
            }

            span {
                font-size: 14px;
                font-weight: 300;
                color: black;
            }

        }

    }

    .door {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .door img {
        width: 100%;
        height: 100%;
    }

    .group:after {
        content: '';
        display: table;
        clear: both;
    }

    .swing {
        cursor: pointer;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform-style: preserve-3d;
        transition: transform 0.5s, box-shadow 0.5s;
        transform-origin: right center;
    }

    .swing figure {
        margin: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        left: -22px;
    }

    .margin-css-1,
    .margin-css-2 {
        margin-left: 74px;

        @media screen and (max-width: 768px) {
            margin-left: 0px;
            margin-top: 50%;
        }

        @media screen and (max-width: 670px) {
            margin-left: 0px;
            margin-top: 70%;
        }

        @media screen and (max-width: 600px) {
            margin-left: 0px;
            margin-top: 80%;
        }

        @media screen and (max-width: 500px) {
            margin-left: 0px;
            margin-top: 100%;
        }


    }

    .door .front {
        width: 200px;
        height: 349px;
        // // background-color: green;
        // width: 150px;
        // height: 225px;
    }

    .door .back {
        background-color: transparent;
        width: 200px;
        height: 349px;
        left: -174px;
    }

    .swing .back {
        transform: rotateY(180deg);
    }

    .swing:hover {
        // outline: 3px solid white;
    }

    .swing.flipped {
        transform: rotateY(120deg);
        // box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.75);
    }

    .popover {
        position: absolute;
        border: 1px solid red;
        width: 200px;
        height: 200px;
        background-color: #fff;
        transition: transform 0.5s;
        transition: box-shadow 0.5s;
        transform: scale3d(0, 0, 0);
    }

    .popover.open {
        transform: scale3d(1, 1, 1);
    }
}

.theme-spinner {
    border: 3px solid $color-white;
    border-top: 3px solid #003568;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: auto;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.link-door {
    a {

        text-decoration: none;
        background-color: #FFBB57;
        border-radius: 50px;
        width: 100px;
        height: 30px;
        padding: 3px 15px;
        font-size: 14px;
        font-weight: 600;
    }

}

.prize-amount {
    font-size: 85px !important;
    font-weight: 900 !important;
}

.prize-email {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    color: #818181;
}