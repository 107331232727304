.championsTable {
    border-radius: 10px !important;
    width: 100%;

    .userImage {
        height: 60px;
        border-radius: 14px;
        width: 60px;
        object-fit: cover;
    }

    .topUser {
        color: #d18a0f;
    }

    .elseUser {
        color: #078550
    }
}


.card-text {
    color: #d18a0f;
}