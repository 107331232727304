body {
  margin: 0;
  padding: 0px;
  font-family: "Lato";
  background-color: #EFEFEF !important;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: "Lato";
  min-height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #EFEFEF;
}

::-webkit-scrollbar-thumb {
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;
}
ul li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px gray inset !important;
  -webkit-text-fill-color: #FFFFFF;
}

.cursor {
  cursor: pointer;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.lh-0-ls-5 {
  line-height: unset !important;
  letter-spacing: 5px;
}

.color-dark {
  color: #9C8B58;
}

.color-black {
  color: #2D2D2D;
}

.color-blue {
  color: #2C3E57;
}

.color-grey {
  color: #949494;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.go-back {
  width: 100%;
  text-align: start;
}

.line-podcast {
  /* Vector 199 */
  opacity: 0.1;
  border: 1px solid #FFFFFF;
}

.vertical-line {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  height: 122px;
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  .vertical-line {
    display: none;
  }
}

.custom-back {
  background-color: #1A212F;
  min-height: 963px;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus {
  border: none !important;
  background-color: gray;
}

.vertical-line-video {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  height: 19px;
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  .vertical-line-video {
    display: none;
  }
}

.default-align {
  align-items: start !important;
}

.heading {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #000000;
}
.heading h1 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #000000;
  width: 60%;
}
@media screen and (max-width: 991px) {
  .heading h1 {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }
}
.heading .sub-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #818181;
}
@media screen and (max-width: 760px) {
  .heading {
    font-size: 34px;
    line-height: 40px;
  }
}
@media screen and (max-width: 400px) {
  .heading {
    font-size: 24px;
    line-height: 30px;
  }
}

.btn-leader-board button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 210px;
  height: 48px;
  background: #FFD242;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.012em;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.thank-you {
  height: 100vh;
}
.thank-you p {
  font-size: 12px;
  font-weight: 600;
}

.thank-you {
  height: 100vh;
}
.thank-you p {
  font-size: 16px;
  font-weight: 600;
}
.thank-you .next-round {
  font-size: 16px;
  font-weight: bolder;
  color: green;
}
.thank-you .next-round span {
  font-size: 16px;
  font-weight: 900;
  color: black;
}

.door-page .group {
  gap: 60px;
}
@media screen and (max-width: 768px) {
  .door-page .group {
    flex-direction: column;
    gap: 100%;
  }
}
.door-page .final-round {
  padding: 10px 0px;
  background: linear-gradient(36.21deg, #FFBB57 33.3%, #FFE0AB 56.23%, #FFA347 82.27%);
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 52px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}
.door-page .container {
  perspective: 5000px;
  margin: 100px;
}
.door-page .doorFrame {
  width: 80px;
  height: 130px;
  float: right;
  background-color: transparent;
  color: #fff;
  padding: 5px 5px 2px 5px;
}
@media screen and (max-width: 768px) {
  .door-page .doorFrame {
    padding: 0px;
    height: 100%;
  }
}
.door-page .doorFrame .promo-code {
  position: absolute;
  z-index: 0;
  width: 100px;
  margin-top: 53px;
}
.door-page .doorFrame .promo-code p {
  font-size: 18px;
  font-weight: 900;
  color: black;
}
.door-page .doorFrame .promo-code span {
  font-size: 14px;
  font-weight: 300;
  color: black;
}
.door-page .door {
  width: 100%;
  height: 100%;
  text-align: center;
}
.door-page .door img {
  width: 100%;
  height: 100%;
}
.door-page .group:after {
  content: "";
  display: table;
  clear: both;
}
.door-page .swing {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transform-style: preserve-3d;
  transition: transform 0.5s, box-shadow 0.5s;
  transform-origin: right center;
}
.door-page .swing figure {
  margin: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  left: -22px;
}
.door-page .margin-css-1,
.door-page .margin-css-2 {
  margin-left: 74px;
}
@media screen and (max-width: 768px) {
  .door-page .margin-css-1,
  .door-page .margin-css-2 {
    margin-left: 0px;
    margin-top: 50%;
  }
}
@media screen and (max-width: 670px) {
  .door-page .margin-css-1,
  .door-page .margin-css-2 {
    margin-left: 0px;
    margin-top: 70%;
  }
}
@media screen and (max-width: 600px) {
  .door-page .margin-css-1,
  .door-page .margin-css-2 {
    margin-left: 0px;
    margin-top: 80%;
  }
}
@media screen and (max-width: 500px) {
  .door-page .margin-css-1,
  .door-page .margin-css-2 {
    margin-left: 0px;
    margin-top: 100%;
  }
}
.door-page .door .front {
  width: 200px;
  height: 349px;
}
.door-page .door .back {
  background-color: transparent;
  width: 200px;
  height: 349px;
  left: -174px;
}
.door-page .swing .back {
  transform: rotateY(180deg);
}
.door-page .swing.flipped {
  transform: rotateY(120deg);
}
.door-page .popover {
  position: absolute;
  border: 1px solid red;
  width: 200px;
  height: 200px;
  background-color: #fff;
  transition: transform 0.5s;
  transition: box-shadow 0.5s;
  transform: scale3d(0, 0, 0);
}
.door-page .popover.open {
  transform: scale3d(1, 1, 1);
}

.theme-spinner {
  border: 3px solid #FFFFFF;
  border-top: 3px solid #003568;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.link-door a {
  text-decoration: none;
  background-color: #FFBB57;
  border-radius: 50px;
  width: 100px;
  height: 30px;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 600;
}

.prize-amount {
  font-size: 85px !important;
  font-weight: 900 !important;
}

.prize-email {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  color: #818181;
}

.spin-wheel .heading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.spin-wheel .heading h1 {
  width: 60%;
  text-align: center;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 760px) {
  .spin-wheel .heading h1 {
    font-size: 34px;
    line-height: 40px;
  }
}
@media screen and (max-width: 400px) {
  .spin-wheel .heading h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.spin-wheel .row-spinner {
  background: url("../images/bg.svg");
  border-radius: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 991px) {
  .spin-wheel .row-spinner {
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }
}
.spin-wheel .content-column {
  background: #613AB3;
  border-radius: 18px;
  padding: 17px;
  width: 40%;
}
@media screen and (max-width: 991px) {
  .spin-wheel .content-column {
    width: 100%;
  }
}
.spin-wheel .content-column .content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.spin-wheel .content-column .content h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 57.854px;
  line-height: 68px;
  text-align: center;
  color: #FFFFFF;
}
@media screen and (max-width: 1400px) {
  .spin-wheel .content-column .content h1 {
    font-size: 50px;
    line-height: 58px;
  }
}
@media screen and (max-width: 1200px) {
  .spin-wheel .content-column .content h1 {
    font-size: 35px;
    line-height: 40px;
  }
}
.spin-wheel .content-column .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;
}
@media screen and (max-width: 1400px) {
  .spin-wheel .content-column .content p {
    font-size: 17px;
    line-height: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .spin-wheel .content-column .content p {
    font-size: 15px;
    line-height: 20px;
  }
}
.spin-wheel .content-column .content h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}
@media screen and (max-width: 1400px) {
  .spin-wheel .content-column .content h3 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .spin-wheel .content-column .content h3 {
    font-size: 18px;
    line-height: 24px;
  }
}
.spin-wheel .content-column .content ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 46px;
  color: #FFFFFF;
}
@media screen and (max-width: 1400px) {
  .spin-wheel .content-column .content ul li {
    font-size: 15px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .spin-wheel .content-column .content ul li {
    font-size: 14px;
    line-height: 24px;
  }
}
.spin-wheel .content-column .content button {
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.012em;
  text-transform: uppercase;
  color: #000000;
  border: none;
  padding: 22px 16px;
}
.spin-wheel .content-column .content button:hover {
  box-shadow: none;
}
.spin-wheel .spinner-column {
  width: 40%;
}
@media screen and (max-width: 991px) {
  .spin-wheel .spinner-column {
    width: 100%;
  }
}

.dots-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  pointer-events: none;
  /* Disable interaction */
  z-index: 1;
}

/* Dots styling */
.dot {
  position: absolute;
  width: 12px;
  /* Adjust size for each light */
  height: 12px;
  background-color: #FFFFF6;
  /* Light color */
  border-radius: 50%;
  box-shadow: 3 3 10px #FFA500;
  /* Optional glowing effect */
}
@media screen and (max-width: 1200px) {
  .dot {
    width: 8px;
    height: 8px;
  }
}

/* Positioning the dots around the border */
.dot-0 {
  top: 2%;
  left: 50%;
}

.dot-1 {
  top: 11%;
  left: 76%;
}

.dot-2 {
  top: 50%;
  left: 96%;
}

.dot-3 {
  top: 78%;
  left: 85%;
}

.dot-4 {
  top: 96%;
  left: 50%;
}

.dot-5 {
  top: 75%;
  left: 10%;
}

.dot-6 {
  top: 50%;
  left: 2%;
}

.dot-7 {
  top: 12%;
  left: 19%;
}

.wheel-container {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 480px;
  height: 480px;
  background: linear-gradient(45deg, #A73D00 14.65%, #562C02 27.37%, #FFBB57 42.22%, #FFF0CE 50.71%, #703200 65.56%, #FFA347 76.16%, #FFF9CE 85.35%);
}
@media screen and (max-width: 1200px) {
  .wheel-container {
    width: 96% !important;
    height: 312px !important;
  }
}
@media screen and (max-width: 1170px) {
  .wheel-container {
    width: 94% !important;
    height: 300px !important;
  }
}
@media screen and (max-width: 991px) {
  .wheel-container {
    width: 51% !important;
    height: 300px !important;
  }
}
@media screen and (max-width: 768px) {
  .wheel-container {
    width: 70% !important;
    height: 297px !important;
  }
}
@media screen and (max-width: 576px) {
  .wheel-container {
    width: 67% !important;
    height: 286px !important;
  }
}
@media screen and (max-width: 576px) {
  .wheel-container {
    width: 70% !important;
    height: 286px !important;
  }
}
@media screen and (max-width: 500px) {
  .wheel-container {
    width: 77% !important;
    height: 286px !important;
  }
}
@media screen and (max-width: 480px) {
  .wheel-container {
    width: 81% !important;
    height: 286px !important;
  }
}
@media screen and (max-width: 426px) {
  .wheel-container {
    width: 94% !important;
    height: 286px !important;
  }
}
@media screen and (max-width: 400px) {
  .wheel-container {
    width: 100% !important;
    height: 286px !important;
  }
}
@media screen and (max-width: 390px) {
  .wheel-container {
    height: 270px !important;
  }
}
@media screen and (max-width: 370px) {
  .wheel-container {
    height: 255px !important;
  }
}
@media screen and (max-width: 365px) {
  .wheel-container {
    height: 240px !important;
  }
}
@media screen and (max-width: 1200px) {
  .wheel-container .sc-gsTCUz {
    width: 24vw !important;
    height: 24vw !important;
  }
}
@media screen and (max-width: 1140px) {
  .wheel-container .sc-gsTCUz {
    width: 24.5vw !important;
    height: 24.5vw !important;
  }
}
@media screen and (max-width: 1111px) {
  .wheel-container .sc-gsTCUz {
    width: 25.5vw !important;
    height: 25.5vw !important;
  }
}
@media screen and (max-width: 1070px) {
  .wheel-container .sc-gsTCUz {
    width: 26vw !important;
    height: 26vw !important;
  }
}
@media screen and (max-width: 1050px) {
  .wheel-container .sc-gsTCUz {
    width: 27vw !important;
    height: 27vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .wheel-container .sc-gsTCUz {
    width: 28vw !important;
    height: 28vw !important;
  }
}
@media screen and (max-width: 980px) {
  .wheel-container .sc-gsTCUz {
    width: 29.5vw !important;
    height: 29.5vw !important;
  }
}
@media screen and (max-width: 950px) {
  .wheel-container .sc-gsTCUz {
    width: 30.5vw !important;
    height: 30.5vw !important;
  }
}
@media screen and (max-width: 870px) {
  .wheel-container .sc-gsTCUz {
    width: 31.5vw !important;
    height: 31.5vw !important;
  }
}
@media screen and (max-width: 855px) {
  .wheel-container .sc-gsTCUz {
    width: 32.5vw !important;
    height: 32.5vw !important;
  }
}
@media screen and (max-width: 840px) {
  .wheel-container .sc-gsTCUz {
    width: 33.5vw !important;
    height: 33.5vw !important;
  }
}
@media screen and (max-width: 820px) {
  .wheel-container .sc-gsTCUz {
    width: 34.5vw !important;
    height: 34.5vw !important;
  }
}
@media screen and (max-width: 800px) {
  .wheel-container .sc-gsTCUz {
    width: 35.5vw !important;
    height: 35.5vw !important;
  }
}
@media screen and (max-width: 768px) {
  .wheel-container .sc-gsTCUz {
    width: 35.5vw !important;
    height: 35.5vw !important;
  }
}
@media screen and (max-width: 750px) {
  .wheel-container .sc-gsTCUz {
    width: 36.5vw !important;
    height: 36.5vw !important;
  }
}
@media screen and (max-width: 720px) {
  .wheel-container .sc-gsTCUz {
    width: 37.5vw !important;
    height: 37.5vw !important;
  }
}
@media screen and (max-width: 700px) {
  .wheel-container .sc-gsTCUz {
    width: 38.5vw !important;
    height: 38.5vw !important;
  }
}
@media screen and (max-width: 670px) {
  .wheel-container .sc-gsTCUz {
    width: 40.5vw !important;
    height: 40.5vw !important;
  }
}
@media screen and (max-width: 650px) {
  .wheel-container .sc-gsTCUz {
    width: 42.5vw !important;
    height: 42.5vw !important;
  }
}
@media screen and (max-width: 630px) {
  .wheel-container .sc-gsTCUz {
    width: 44.5vw !important;
    height: 44.5vw !important;
  }
}
@media screen and (max-width: 600px) {
  .wheel-container .sc-gsTCUz {
    width: 47vw !important;
    height: 47vw !important;
  }
}
@media screen and (max-width: 570px) {
  .wheel-container .sc-gsTCUz {
    width: 49vw !important;
    height: 49vw !important;
  }
}
@media screen and (max-width: 520px) {
  .wheel-container .sc-gsTCUz {
    width: 51vw !important;
    height: 51vw !important;
  }
}
@media screen and (max-width: 500px) {
  .wheel-container .sc-gsTCUz {
    width: 53vw !important;
    height: 53vw !important;
  }
}
@media screen and (max-width: 480px) {
  .wheel-container .sc-gsTCUz {
    width: 54.5vw !important;
    height: 54.5vw !important;
  }
}
@media screen and (max-width: 426px) {
  .wheel-container .sc-gsTCUz {
    width: 61.5vw !important;
    height: 61.5vw !important;
  }
}
@media screen and (max-width: 400px) {
  .wheel-container .sc-gsTCUz {
    width: 64.5vw !important;
    height: 64.5vw !important;
  }
}
@media screen and (max-width: 365px) {
  .wheel-container .sc-gsTCUz {
    width: 61.5vw !important;
    height: 61.5vw !important;
  }
}
.wheel-container .sc-gsTCUz .gTLZXx {
  position: absolute;
  z-index: 5 !important;
  width: 17% !important;
  right: 179px !important;
  top: -27px !important;
  height: 68px;
}
@media screen and (max-width: 1200px) {
  .wheel-container .sc-gsTCUz .gTLZXx {
    right: 114px !important;
    top: -18px !important;
    height: 42px;
  }
}
@media screen and (max-width: 1170px) {
  .wheel-container .sc-gsTCUz .gTLZXx {
    right: 114px !important;
    top: -14px !important;
  }
}
@media screen and (max-width: 400px) {
  .wheel-container .sc-gsTCUz .gTLZXx {
    right: 108px !important;
    top: -17px !important;
  }
}
@media screen and (max-width: 390px) {
  .wheel-container .sc-gsTCUz .gTLZXx {
    top: -11px !important;
    height: 38px;
  }
}
@media screen and (max-width: 370px) {
  .wheel-container .sc-gsTCUz .gTLZXx {
    right: 100px !important;
  }
}
@media screen and (max-width: 365px) {
  .wheel-container .sc-gsTCUz .gTLZXx {
    right: 95px !important;
  }
}

.roulette-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: white;
  font-size: 0.8rem;
  font-weight: bold;
  transform: translate(-55%, -55%);
}
.roulette-button img {
  width: 108px;
  height: 108px;
}
@media screen and (max-width: 1200px) {
  .roulette-button img {
    width: 100%;
    height: auto;
  }
}

.button {
  font-size: larger;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fiHase {
  position: absolute;
  width: 100%;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-237.578deg);
}

.mcqs .quiz-component .question-sec {
  background: #F3F3F3;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 50px;
}
.mcqs .quiz-component .question-sec .quiz h3 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}
.mcqs .quiz-component .question-sec .quiz .options {
  gap: 14px;
  flex-wrap: wrap;
}
.mcqs .quiz-component .question-sec .quiz .options p {
  width: 294.65px;
  height: 66.29px;
  background: #CBCBCB;
  border-radius: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.012em;
  color: #000000;
  cursor: pointer;
}
.mcqs .quiz-component .question-sec .quiz .options p.active {
  background-color: #FFD242;
}
.mcqs .quiz-component .question-sec .quiz .options p.wrong-option {
  background-color: red;
  color: white;
}
.mcqs .duration-details {
  gap: 20px;
}
.mcqs .duration-details .time {
  background: #F3F3F3;
  border-radius: 18px;
  padding: 20px;
  width: 100%;
}
.mcqs .duration-details .time p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}
.mcqs .duration-details .time .time-left {
  padding: 8px 20px;
  background: #000000;
  border-radius: 91.937px;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 31.9781px;
  line-height: 48px;
  color: #FFFFFF;
}
.mcqs .duration-details .score {
  background: #F3F3F3;
  border-radius: 18px;
  padding: 20px;
  width: 100%;
}
.mcqs .duration-details .score p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}
.mcqs .duration-details .score .score-count {
  font-style: normal;
  font-weight: 400;
  font-size: 71.8713px;
  line-height: 84px;
  color: #000000;
}
.mcqs .duration-details button {
  height: 86.29px;
  background: #4FC553;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */
  letter-spacing: 0.012em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.mcqs .duration-details button:hover, .mcqs .duration-details button:active, .mcqs .duration-details button:focus {
  background: #4FC553 !important;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: none !important;
}

/* Stepper container and layout */
.stepper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  /* Stepper line */
}
.stepper-container .step-container {
  display: flex;
  align-items: center;
}
.stepper-container .step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper-container .step.completed {
  background-color: #4f46e5;
}
.stepper-container .step.current,
.stepper-container .step.inactive {
  border: 2px solid #4f46e5;
  padding: 10px;
}
.stepper-container .step.inactive {
  border-color: #d1d5d8;
}
.stepper-container .step.current .inner-dot,
.stepper-container .step.inactive .inner-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.stepper-container .step.current .inner-dot {
  background-color: #4f46e5;
}
.stepper-container .step.inactive .inner-dot {
  background-color: #d1d5d8;
}
.stepper-container .step-line {
  height: 2px;
  width: 60px;
  /* margin: 0 10px; */
}
.stepper-container .completed-line {
  background-color: #4f46e5;
}
.stepper-container .inactive-line {
  background-color: #d1d5d8;
}


/**
* MARK: GUESS GAME 
*/
.main-form {
  max-width: 600px;
  margin: 0 auto;
}

.guess_price {
  border: none;
  background: white !important;
  background-color: white !important;
}

.guess_price:focus,
.guess_price:hover,
.guess_price:active {
  outline: none;
  border: none;
  background: white !important;
  background-color: white !important;
}

.rate {
  font-size: 80px;
}

.disable-btn {
  background: #9C998E;
}

.next-btn {
  background: #4FC553;
}

/**
  * Modal
  */
.modal-content {
  border-radius: 20px;
  padding: 10px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 2px;
  padding-top: 8px;
}

.modal-body {
  /* max-height: 600px !important;
  overflow-y: scroll; */
}

.modal-footer {
  border-top: none;
}

.leader-board {
  max-height: 600px !important;
  overflow-y: scroll;
}

.leader-board::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.leader-board::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #FFFFFF;
}

.leader-board::-webkit-scrollbar-track:hover {
  background-color: #FFFFFF;
}

.leader-board::-webkit-scrollbar-track:active {
  background-color: #FFFFFF;
}

.leader-board::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #D9D9D9;
}

.leader-board::-webkit-scrollbar-thumb:hover {
  background-color: #D9D9D9;
}

.leader-board::-webkit-scrollbar-thumb:active {
  background-color: #D9D9D9;
}

.championsTable {
  border-radius: 10px !important;
  width: 100%;
}
.championsTable .userImage {
  height: 60px;
  border-radius: 14px;
  width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
}
.championsTable .topUser {
  color: #d18a0f;
}
.championsTable .elseUser {
  color: #078550;
}

.card-text {
  color: #d18a0f;
}/*# sourceMappingURL=global.css.map */