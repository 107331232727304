.mcqs {
    .quiz-component {
        .question-sec {
            background: #F3F3F3;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px 50px;

            .quiz {
                h3 {
                    font-family: 'Public Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 40px;
                    text-align: center;
                    color: #000000;
                }

                .options {
                    gap: 14px;
                    flex-wrap: wrap;

                    p {
                        width: 294.65px;
                        height: 66.29px;
                        background: #CBCBCB;
                        border-radius: 12px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        letter-spacing: 0.012em;
                        color: #000000;
                        cursor: pointer;

                        &.active {
                            background-color: #FFD242;
                        }

                        &.wrong-option {
                            background-color: red;
                            color: white;
                        }
                    }
                }

               
            }
        }
    }

    .duration-details {
        gap: 20px;

        .time {
            background: #F3F3F3;
            border-radius: 18px;
            padding: 20px;
            width: 100%;

            p {
                font-family: 'Public Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                color: #000000;
            }

            .time-left {
                padding: 8px 20px;
                background: #000000;
                border-radius: 91.937px;
                font-family: 'Public Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 31.9781px;
                line-height: 48px;
                color: #FFFFFF;
            }
        }

        .score {
            background: #F3F3F3;
            border-radius: 18px;
            padding: 20px;
            width: 100%;

            p {
                font-family: 'Public Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                color: #000000;
            }

            .score-count {
                font-style: normal;
                font-weight: 400;
                font-size: 71.8713px;
                line-height: 84px;
                color: #000000;
            }

        }

        button {
            height: 86.29px;
            background: #4FC553;
            border-radius: 12px;
            border: none;
            box-shadow: none;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            /* identical to box height */
            letter-spacing: 0.012em;
            text-transform: uppercase;
            color: #FFFFFF;

            &:hover,
            &:active,
            &:focus {
                background: #4FC553 !important;
                border-radius: 12px !important;
                border: none !important;
                box-shadow: none !important;
            }

        }
    }
}

/* Stepper container and layout */
.stepper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;


    .step-container {
        display: flex;
        align-items: center;
    }

    .step {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .step.completed {
        background-color: #4f46e5;
    }

    .step.current,
    .step.inactive {
        border: 2px solid #4f46e5;
        padding: 10px;
    }

    .step.inactive {
        border-color: #d1d5d8;
    }

    .step.current .inner-dot,
    .step.inactive .inner-dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }

    .step.current .inner-dot {
        background-color: #4f46e5;
    }

    .step.inactive .inner-dot {
        background-color: #d1d5d8;
    }

    /* Stepper line */
    .step-line {
        height: 2px;
        width: 60px;
        /* margin: 0 10px; */
    }

    .completed-line {
        background-color: #4f46e5;
    }

    .inactive-line {
        background-color: #d1d5d8;
    }
}