.spin-wheel {
    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
            // font-style: normal;
            // font-weight: 500;
            // font-size: 44.0385px;
            // line-height: 38px;
            // text-align: center;
            // color: #000000;
            width: 60%;
            text-align: center;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 47px;
            text-align: center;
            color: #000000;

            @media screen and (max-width: 760px) {
                font-size: 34px;
                line-height: 40px;
            }

            @media screen and (max-width: 400px) {
                font-size: 24px;
                line-height: 30px;
            }

        }
    }

    .row-spinner {
        background: url("../images/bg.svg");
        border-radius: 50px;
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 50px;
            justify-content: center;
            align-items: center;
        }
    }


    .content-column {
        background: #613AB3;
        border-radius: 18px;
        padding: 17px;
        width: 40%;

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;

            h1 {
                font-style: normal;
                font-weight: 600;
                font-size: 57.854px;
                line-height: 68px;
                text-align: center;
                color: #FFFFFF;

                @media screen and (max-width: 1400px) {
                    font-size: 50px;
                    line-height: 58px;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 35px;
                    line-height: 40px;
                }
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 29px;
                color: #FFFFFF;

                @media screen and (max-width: 1400px) {
                    font-size: 17px;
                    line-height: 25px;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 15px;
                    line-height: 20px;
                }

            }

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                color: #FFFFFF;

                @media screen and (max-width: 1400px) {
                    font-size: 20px;
                    line-height: 25px;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            ul {
                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 46px;
                    color: #FFFFFF;

                    @media screen and (max-width: 1400px) {
                        font-size: 15px;
                        line-height: 30px;
                    }

                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                        line-height: 24px;
                    }

                }
            }

            button {
                width: 100%;
                background: #FFFFFF;
                border-radius: 12px;

                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0.012em;
                text-transform: uppercase;
                color: #000000;
                border: none;
                padding: 22px 16px;

                &:hover {
                    box-shadow: none;
                }
            }
        }
    }

    .spinner-column {
        width: 40%;

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

.dots-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
    /* Disable interaction */
    z-index: 1;
}

/* Dots styling */
.dot {
    position: absolute;
    width: 12px;
    /* Adjust size for each light */
    height: 12px;
    background-color: #FFFFF6;
    /* Light color */
    border-radius: 50%;
    box-shadow: 3 3 10px #FFA500;

    /* Optional glowing effect */
    @media screen and (max-width: 1200px) {
        width: 8px;
        height: 8px;

    }
}

/* Positioning the dots around the border */
.dot-0 {
    top: 2%;
    left: 50%;
}

.dot-1 {
    top: 11%;
    left: 76%;
}

.dot-2 {
    top: 50%;
    left: 96%;
}

.dot-3 {
    top: 78%;
    left: 85%;
}

.dot-4 {
    top: 96%;
    left: 50%;
}

.dot-5 {
    top: 75%;
    left: 10%;
}

.dot-6 {
    top: 50%;
    left: 2%;
}

.dot-7 {
    top: 12%;
    left: 19%;
}


.wheel-container {
    position: relative;
    display: inline-block;
    // padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    width: 480px;
    height: 480px;
    background: linear-gradient(45deg, #A73D00 14.65%, #562C02 27.37%, #FFBB57 42.22%, #FFF0CE 50.71%, #703200 65.56%, #FFA347 76.16%, #FFF9CE 85.35%);

    @media screen and (max-width: 1200px) {
        width: 96% !important;
        height: 312px !important;
    }

    @media screen and (max-width: 1170px) {
        width: 94% !important;
        height: 300px !important;
    }

    @media screen and (max-width: 991px) {
        width: 51% !important;
        height: 300px !important;
    }

    @media screen and (max-width: 768px) {
        width: 70% !important;
        height: 297px !important;
    }

    @media screen and (max-width: 576px) {
        width: 67% !important;
        height: 286px !important;
    }

    @media screen and (max-width: 576px) {
        width: 70% !important;
        height: 286px !important;
    }

    @media screen and (max-width: 500px) {
        width: 77% !important;
        height: 286px !important;
    }

    @media screen and (max-width: 480px) {
        width: 81% !important;
        height: 286px !important;
    }

    @media screen and (max-width: 426px) {
        width: 94% !important;
        height: 286px !important;
    }

    @media screen and (max-width: 400px) {
        width: 100% !important;
        height: 286px !important;
    }

    @media screen and (max-width: 390px) {
        height: 270px !important;
    }

    @media screen and (max-width: 370px) {
        height: 255px !important;
    }

    @media screen and (max-width: 365px) {
        height: 240px !important;
    }

    .sc-gsTCUz {
        @media screen and (max-width: 1200px) {
            width: 24vw !important;
            height: 24vw !important;
        }

        @media screen and (max-width: 1140px) {
            width: 24.5vw !important;
            height: 24.5vw !important;
        }

        @media screen and (max-width: 1111px) {
            width: 25.5vw !important;
            height: 25.5vw !important;
        }

        @media screen and (max-width: 1070px) {
            width: 26vw !important;
            height: 26vw !important;
        }

        @media screen and (max-width: 1050px) {
            width: 27vw !important;
            height: 27vw !important;
        }

        @media screen and (max-width: 1000px) {
            width: 28vw !important;
            height: 28vw !important;
        }

        @media screen and (max-width: 980px) {
            width: 29.5vw !important;
            height: 29.5vw !important;
        }

        @media screen and (max-width: 950px) {
            width: 30.5vw !important;
            height: 30.5vw !important;
        }

        @media screen and (max-width: 870px) {
            width: 31.5vw !important;
            height: 31.5vw !important;
        }

        @media screen and (max-width: 855px) {
            width: 32.5vw !important;
            height: 32.5vw !important;
        }

        @media screen and (max-width: 840px) {
            width: 33.5vw !important;
            height: 33.5vw !important;
        }

        @media screen and (max-width: 820px) {
            width: 34.5vw !important;
            height: 34.5vw !important;
        }

        @media screen and (max-width: 800px) {
            width: 35.5vw !important;
            height: 35.5vw !important;
        }

        @media screen and (max-width: 768px) {
            width: 35.5vw !important;
            height: 35.5vw !important;
        }

        @media screen and (max-width: 750px) {
            width: 36.5vw !important;
            height: 36.5vw !important;
        }

        @media screen and (max-width: 720px) {
            width: 37.5vw !important;
            height: 37.5vw !important;
        }

        @media screen and (max-width: 700px) {
            width: 38.5vw !important;
            height: 38.5vw !important;
        }

        @media screen and (max-width: 670px) {
            width: 40.5vw !important;
            height: 40.5vw !important;
        }

        @media screen and (max-width: 650px) {
            width: 42.5vw !important;
            height: 42.5vw !important;
        }

        @media screen and (max-width: 630px) {
            width: 44.5vw !important;
            height: 44.5vw !important;
        }

        @media screen and (max-width: 600px) {
            width: 47vw !important;
            height: 47vw !important;
        }

        @media screen and (max-width: 570px) {
            width: 49vw !important;
            height: 49vw !important;
        }

        @media screen and (max-width: 520px) {
            width: 51vw !important;
            height: 51vw !important;
        }

        @media screen and (max-width: 500px) {
            width: 53vw !important;
            height: 53vw !important;
        }

        @media screen and (max-width: 480px) {
            width: 54.5vw !important;
            height: 54.5vw !important;
        }

        @media screen and (max-width: 426px) {
            width: 61.5vw !important;
            height: 61.5vw !important;
        }

        @media screen and (max-width: 400px) {
            width: 64.5vw !important;
            height: 64.5vw !important;
        }

        @media screen and (max-width: 365px) {
            width: 61.5vw !important;
            height: 61.5vw !important;
        }

        // @media screen and (max-width: 800px) {
        //     width: 36.5vw !important;
        //     height: 36.5vw !important;
        // }

        .gTLZXx {
            position: absolute;
            z-index: 5 !important;
            width: 17% !important;
            right: 179px !important;
            top: -27px !important;
            height: 68px;

            @media screen and (max-width: 1200px) {
                right: 114px !important;
                top: -18px !important;
                height: 42px;
            }

            @media screen and (max-width: 1170px) {
                right: 114px !important;
                top: -14px !important;
            }

            @media screen and (max-width: 400px) {
                right: 108px !important;
                top: -17px !important;
            }

            // @media screen and (max-width: 1170px) {
            //     top: -17px !important;
            // }
            // @media screen and (max-width: 1100px) {
            //     top: -20px !important;
            // }
            @media screen and (max-width: 390px) {
                top: -11px !important;
                height: 38px;
            }

            @media screen and (max-width: 370px) {
                right: 100px !important;
            }

            @media screen and (max-width: 365px) {
                right: 95px !important;
            }


        }
    }

}


.roulette-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: white;
    font-size: 0.8rem;
    font-weight: bold;
    transform: translate(-55%, -55%);

    img {
        width: 108px;
        height: 108px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            height: auto;
        }
    }
}

.button {
    font-size: larger;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

// .eRaOwP {
//     transform: rotate(-66.7159deg);
// }

.fiHase {
    position: absolute;
    width: 100%;
    inset: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transform: rotate(-237.578deg);
}