/* Stepper container and layout */
.stepper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.step-container {
    display: flex;
    align-items: center;
}

.step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step.completed {
    background-color: #4f46e5;
}

.step.current,
.step.inactive {
    border: 2px solid #4f46e5;
    padding: 10px;
}

.step.inactive {
    border-color: #d1d5d8;
}

.step.current .inner-dot,
.step.inactive .inner-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.step.current .inner-dot {
    background-color: #4f46e5;
}

.step.inactive .inner-dot {
    background-color: #d1d5d8;
}

/* Stepper line */
.step-line {
    height: 2px;
    width: 60px;
    /* margin: 0 10px; */
}

.completed-line {
    background-color: #4f46e5;
}

.inactive-line {
    background-color: #d1d5d8;
}