/*# sourceMappingURL=global.css.map */


/**
* MARK: GUESS GAME 
*/

.main-form {
    max-width: 600px;
    margin: 0 auto;
}

.guess_price {
    border: none;
    background: white !important;
    background-color: white !important;
}

.guess_price:focus,
.guess_price:hover,
.guess_price:active {
    outline: none;
    border: none;
    background: white !important;
    background-color: white !important;
}

.rate {
    font-size: 80px;
}

.disable-btn {
    background: #9C998E;
}

.next-btn {
    background: #4FC553;

}

/**
  * Modal
  */
.modal-content {
    border-radius: 20px;
    padding: 10px;
}

.modal-header {
    border-bottom: none;
    padding-bottom: 2px;
    padding-top: 8px;
}

.modal-body {
    /* max-height: 600px !important;
    overflow-y: scroll; */
}

.modal-footer {
    border-top: none;
}

.leader-board {
    max-height: 600px !important;
    overflow-y: scroll;
}

.leader-board::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.leader-board::-webkit-scrollbar-track {
    border-radius: 7px;
    background-color: #FFFFFF;
}

.leader-board::-webkit-scrollbar-track:hover {
    background-color: #FFFFFF;
}

.leader-board::-webkit-scrollbar-track:active {
    background-color: #FFFFFF;
}

.leader-board::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #D9D9D9;
}

.leader-board::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
}

.leader-board::-webkit-scrollbar-thumb:active {
    background-color: #D9D9D9;
}